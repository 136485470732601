<template>
  <div class="main">
    <a-card title="演示">
      <a-form layout="vertical">
        <a-form-item label="视频链接">
          <a-input v-model:value="url" placeholder="请输入视频链接"/>
        </a-form-item>
        <a-form-item label="响应结果">
          <a-textarea v-model:value="form.data.raw" :autoSize="{minRows:8,maxRows:8}"/>
        </a-form-item>
        <a-form-item label="AI处理">
          <a-textarea v-model:value="form.data.ai" :autoSize="{minRows:8,maxRows:8}"/>
        </a-form-item>
      </a-form>
      <div class="apply">
        <a-space>
          <a-button :loading="loading" type="primary" @click="extra">{{ loading ? "正在提取" : "提取评论" }}</a-button>
          <a-button :loading="loading" type="primary" @click="apply">{{ loading ? "正在识别" : "识别文案" }}</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>
<script setup>
/* eslint-disable */
import {reactive, ref} from "vue";
import load from "@/common/load";
import axios from "axios";
import {sleep} from "@/common/common";
import {store} from "@/common";
import dayjs from "dayjs";

/* 参数 */
const url = ref(null);
const loading = ref(false);

/* 关联表单 */
const form = store({
  ai: "",
  raw: ""
})

/* 任务ID */
let taskId = null;

/* 是否是链接 */
const isHttpURL = (url) => {
  const pattern = /^(http:\/\/|https:\/\/).*/;
  return pattern.test(url);
}


/**
 * 提交任务
 */
const apply = () => {

  /* 判断链接 */
  if (!isHttpURL(url.value)) {
    load.error("视频链接错误")
    return;
  }

  /* 禁止重复提交 */
  if (loading.value) {
    return;
  }

  /* 重置 */
  form.reset();
  loading.value = true;

  /* 显示加载效果 */
  load.loading("加载中...");

  try {
    /* 开始请求 */
    axios.post("https://text.nicen.cn/api/post", {
      url: url.value
    })
        .then((res) => {
          /*
          * 判断请求结果
          * */
          if (res.data.code) {
            load.success("任务提交成功！")
            taskId = res.data.taskId;
            pool();
          } else {
            /* 弹出错误原因 */
            load.error(res.data.msg);
            loading.value = false;
          }
        }).catch((e) => {
      /* 弹出错误原因 */
      load.error(e.message);
    }).finally(() => {
      /* 关闭加载效果 */
      load.loaded();
    });
  } catch (e) {
    console.log(e)
    load.error(e);
  }

}


const extra = () => {

  /* 判断链接 */
  if (!isHttpURL(url.value)) {
    load.error("视频链接错误")
    return;
  }

  /* 禁止重复提交 */
  if (loading.value) {
    return;
  }

  /* 重置 */
  form.reset();
  loading.value = true;

  /* 显示加载效果 */
  load.loading("加载中...");

  try {
    /* 开始请求 */
    axios.post("https://text.nicen.cn/api/comments", {
      url: url.value
    })
        .then((res) => {
          /*
          * 判断请求结果
          * */
          if (res.data.code) {
            form.data.raw = res.data.data.comments.map(item => {
              const time = dayjs(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
              return `${time} ${item.user.nickname}：${item.text}`;
            }).join("\n")
          } else {
            /* 弹出错误原因 */
            load.error(res.data.msg);
            loading.value = false;
          }
        }).catch((e) => {
      /* 弹出错误原因 */
      load.error(e.message);
    }).finally(() => {
      /* 关闭加载效果 */
      load.loaded();
      loading.value = false;
    });
  } catch (e) {
    console.log(e)
    load.error(e);
  }

}


/**
 * 获取结果
 */
const pool = async () => {

  /* 轮询结果 */
  while (true) {


    /* 显示加载效果 */
    load.loading("正在识别...");


    /* 获取结果 */
    const res = await new Promise(resolve => {

      try {
        /* 开始请求 */
        axios.post("https://text.nicen.cn/api/get", {
          taskId: taskId
        })
            .then((res) => {
              /*
              * 判断请求结果
              * */
              if (res.data.code) {
                resolve(res.data.data);
              } else {
                resolve(false);
              }
            }).catch((e) => {
          /* 弹出错误原因 */
          load.error(e.message);
          resolve(false);
        })
      } catch (e) {
        load.error(e);
        resolve(false);
      }
    })

    /* 休眠 */
    await sleep(3000);

    /* 假如有结果 */
    if (res) {
      load.warning(`识别成功！耗时${res.time.toFixed(2)}s！`)
      form.data.raw = res.raw;
      form.data.ai = res.ai;
      loading.value = false;
      load.loaded(true);
      break;
    }

  }

}

</script>

<style scoped lang="scss">


.main {
  max-width: 800px;
  margin: 30px auto;
  @include full;

  .apply {
    @include flex-center;
    justify-content: center;
    width: 100%;
  }
}
</style>